import { Inscription } from '../globals/types';
import api from '../services/api';
import { useAuth } from '../context/AuthProvider';

const useInscription = () => {
  const { user } = useAuth();

  const createInscription = async (inscription: Inscription): Promise<Inscription> => {
    inscription.user = user?._id as string;
    inscription.fishingEvent = process.env.REACT_APP_FISHING_EVENT_ID as string;
    const { data } = await api.post('/inscriptions', inscription);
    return data;
  };

  const fetchInscription = async (id: string): Promise<Inscription> => {
    const { data } = await api.get(`/inscriptions/${id}`);
    return data;
  };

  const patchInscription = async (id: string, inscription: Partial<Inscription>): Promise<Inscription> => {
    const { data } = await api.patch(`/inscriptions/${id}`, inscription);
    return data;
  };

  const fetchUserInscriptions = async (): Promise<Inscription[]> => {
    const { data } = await api.get(`users/${user?._id}/inscriptions`);
    return data;
  };

  return { createInscription, fetchInscription, patchInscription, fetchUserInscriptions };
};

export { useInscription };
