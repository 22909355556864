import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-size: cover;

  &.mobile {
    flex-direction: column;
  }
`;

export const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 15px 35px;
  min-height: 100vh;
  overflow-y: scroll;
  max-width: 100vw;
  width: 420px;

  ${MainBox}.mobile & {
    width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .register-button {
    margin: 40px 0 20px 0;
  }

  .redirection-sign-up {
    text-decoration: none;
    display: flex;
    flex-direction: row;

    .sign-up-link {
      margin-left: 5px;
    }
  }
`;
