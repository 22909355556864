import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LogoBox = styled(Box)`
  background: url(/images/logo.svg) no-repeat;
  background-size: contain;

  &.mobile {
    width: 158px;
    height: 124px !important;
    margin-top: 5px;
  }

  &.desktop {
    width: 198px;
    height: 155px !important;
  }
`;
