import { createContext, useContext, useState } from 'react';
import { Credentials, User } from '../globals/types';
import api from '../services/api';

const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);
const authApiPath = '/auth';
const usersApiPath = '/users';
const localStorageUserKey = '@pescachaco:user';

interface AuthContextInterface {
  user: User | undefined;
  signUp(user: User): Promise<User>;
  signIn(credentials: Credentials): Promise<User>;
  signOut(): void;
}

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | undefined>();

  const signUp = async (user: User) => {
    await api.post(usersApiPath, user);
    return signIn({ email: user.email, password: user.password });
  };

  const signIn = async (credentials: Credentials): Promise<User> => {
    const { data } = await api.post(authApiPath, credentials);
    if (data.access_token) {
      api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      localStorage.setItem(localStorageUserKey, JSON.stringify(data));
      setUser(data.user);
    }
    return data;
  };

  const signOut = async () => {
    const { data } = await api.post(`${authApiPath}/logout`);
    delete api.defaults.headers.common.Authorization;
    localStorage.removeItem(localStorageUserKey);
    setUser({} as User);
    return data;
  };

  const loadUserFromLocalStorage = () => {
    if (localStorage.getItem(localStorageUserKey)) {
      const localStorageUser = JSON.parse(localStorage.getItem(localStorageUserKey) as string);
      verifyAuth(localStorageUser.access_token);
      if (!user) {
        setUser(localStorageUser.user);
        api.defaults.headers.common.Authorization = `Bearer ${localStorageUser.access_token}`;
      }
    }
  };

  const verifyAuth = (accessToken: string) => {
    const decodedJwt = parseJwt(accessToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      signOut();
    }
  };

  const parseJwt = (accessToken: string) => {
    try {
      return JSON.parse(window.atob(accessToken.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  loadUserFromLocalStorage();

  return <AuthContext.Provider value={{ user, signUp, signIn, signOut }}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
