import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Competitor, Gender, Inscription } from '../../globals/types';
import Carousel from '../../components/Carousel/Carousel';
import Hamburger from '../../components/Hamburger/Hamburger';
import PescaChacoLogo from '../../components/PescaChacoLogo/PescaChacoLogo';
import { useInscription } from '../../hooks/useInscription';
import { useScreen } from '../../hooks/useScreen';
import { ButtonRemoveCompetitor, FormBox, InfoCard, MainBox } from './InscriptionForm.styles';

const competitorSchema = yup.object().shape({
  firstName: yup.string().required('Campo requerido'),
  lastName: yup.string().required('Campo requerido'),
  gender: yup.string().required('Campo requerido'),
  dateOfBirth: yup.date().required('Campo requerido'),
  idNumber: yup.string().required('Campo requerido'),
  address: yup.string().required('Campo requerido'),
  zipCode: yup.string().required('Campo requerido'),
  country: yup.string().required('Campo requerido'),
  state: yup.string().required('Campo requerido'),
  city: yup.string().required('Campo requerido'),
  tel: yup.string(),
  email: yup.string().required('Campo requerido'),
  fishingLicense: yup.string().required('Campo requerido'),
  helmsmanLicense: yup.string(),
});

const inscriptionSchema = yup.object().shape({
  boatName: yup.string().required('Campo requerido'),
  hp: yup.number().typeError('El valor debe ser un numérico').required('Campo requerido'),
  plateNumber: yup.string().required('Campo requerido'),
  competitors: yup.array().of(competitorSchema).min(1),
});

const ArStates = [
  'Buenos Aires',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Ciudad de Buenos Aires',
  'Cordoba',
  'Corrientes',
  'Entre Rios',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquen',
  'Rio Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucuman',
];

const InscriptionForm = () => {
  const { isDesktop, isMobile } = useScreen();
  const navigate = useNavigate();
  const { createInscription } = useInscription();
  const [showStates, setShowStates] = useState<boolean>(true);
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<Inscription>({ resolver: yupResolver(inscriptionSchema) });
  const { fields, append, remove } = useFieldArray({
    name: 'competitors',
    control,
    rules: { maxLength: 4 },
  });

  const submitForm = (data: Inscription) => {
    setApiError(null);
    createInscription(data)
      .then((inscriptionCreated) => navigate(`/inscriptions/${inscriptionCreated._id}/table-reservation`))
      .catch((reason) => {
        if (reason.response.data.message === 'Boat already inscribed') {
          setApiError('Ya existe una embarcación registrada con esta matrícula');
        }
      });
  };

  return (
    <>
      <Hamburger />
      <MainBox>
        <FormBox
          component='form'
          onSubmit={handleSubmit(submitForm)}
          className={isMobile ? 'mobile' : ''}
        >
          <PescaChacoLogo />
          <Typography variant='h1'>Inscripción</Typography>
          <Typography variant='h3'>A continuación complete sus datos de la embarcación y de los tripulantes</Typography>
          <InfoCard>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                className='boat-info'
              >
                <Typography variant='h2'>Embarcación</Typography>
              </Grid>
              <Grid
                item
                xs={9}
              >
                <TextField
                  {...register('boatName')}
                  label='Nombre de la embarcación'
                  helperText={errors.boatName?.message}
                  error={!!errors.boatName?.message}
                  variant='standard'
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <TextField
                  {...register('hp')}
                  label='HP'
                  helperText={errors.hp?.message}
                  error={!!errors.hp?.message}
                  variant='standard'
                  type='text'
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  {...register('plateNumber')}
                  label='Matrícula'
                  helperText={errors.plateNumber?.message}
                  error={!!errors.plateNumber?.message}
                  variant='standard'
                  type='text'
                  fullWidth
                />
              </Grid>
            </Grid>
          </InfoCard>

          {fields.map((field, i) => (
            <InfoCard key={field.id}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant='h5'>
                    Tripulante {i + 1} {!i ? '(Timonel)' : ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    {...register(`competitors.${i}.firstName`)}
                    label='Nombre'
                    helperText={errors.competitors && errors.competitors[i]?.firstName?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.firstName?.message)}
                    variant='standard'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <TextField
                    {...register(`competitors.${i}.lastName`)}
                    label='Apellido'
                    helperText={errors.competitors && errors.competitors[i]?.lastName?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.lastName?.message)}
                    variant='standard'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={3}
                >
                  <FormControl
                    variant='standard'
                    fullWidth
                  >
                    <InputLabel id={`competitor${i}-gender-label`}>Sexo</InputLabel>
                    <Select
                      {...register(`competitors.${i}.gender`)}
                      defaultValue={'male'}
                      onChange={(element) => setValue(`competitors.${i}.gender`, element.target.value as Gender)}
                      labelId={`competitor${i}-gender-label`}
                      label='Sexo'
                      error={!!(errors.competitors && errors.competitors[i]?.gender?.message)}
                    >
                      <MenuItem value={'male'}>Masculino</MenuItem>
                      <MenuItem value={'female'}>Femenino</MenuItem>
                      <MenuItem value={'other'}>Otro</MenuItem>
                      <MenuItem value={'undefined'}>Prefiero no decirlo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={4}
                >
                  <Controller
                    name={`competitors.${i}.dateOfBirth`}
                    control={control}
                    render={({ field: { value } }) => (
                      <DatePicker
                        label='Date of birth'
                        inputFormat='DD/MM/YYYY'
                        disableFuture
                        openTo='year'
                        value={value}
                        onChange={(value) => setValue(`competitors.${i}.dateOfBirth`, value as Date)}
                        renderInput={(params) => (
                          <TextField
                            {...register(`competitors.${i}.dateOfBirth`)}
                            variant='standard'
                            helperText={errors.competitors && errors.competitors[i]?.dateOfBirth?.message}
                            error={!!(errors.competitors && errors.competitors[i]?.dateOfBirth?.message)}
                            value={params.value}
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <TextField
                    {...register(`competitors.${i}.idNumber`)}
                    label='Tipo y N° de documento'
                    helperText={errors.competitors && errors.competitors[i]?.idNumber?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.idNumber?.message)}
                    variant='standard'
                    type='string'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                >
                  <TextField
                    {...register(`competitors.${i}.address`)}
                    label='Dirección'
                    helperText={errors.competitors && errors.competitors[i]?.address?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.address?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={4}
                >
                  <TextField
                    {...register(`competitors.${i}.zipCode`)}
                    label='Cod. Postal'
                    helperText={errors.competitors && errors.competitors[i]?.zipCode?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.zipCode?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={4}
                >
                  <FormControl
                    variant='standard'
                    fullWidth
                  >
                    <InputLabel id={`competitor${i}-country-label`}>País</InputLabel>
                    <Select
                      {...register(`competitors.${i}.country`)}
                      defaultValue={'AR'}
                      onChange={(element) => {
                        setValue(`competitors.${i}.country`, element.target.value);
                        setValue(`competitors.${i}.state`, '');
                        setShowStates(element.target.value === 'AR');
                      }}
                      labelId={`competitor${i}-country-label`}
                      label='País'
                      error={!!(errors.competitors && errors.competitors[i]?.country?.message)}
                    >
                      <MenuItem value={'AR'}>Argentina</MenuItem>
                      <MenuItem value={'BO'}>Bolivia</MenuItem>
                      <MenuItem value={'BR'}>Brasil</MenuItem>
                      <MenuItem value={'CL'}>Chile</MenuItem>
                      <MenuItem value={'CN'}>China</MenuItem>
                      <MenuItem value={'CO'}>Colombia</MenuItem>
                      <MenuItem value={'EC'}>Ecuador</MenuItem>
                      <MenuItem value={'US'}>Estados Unidos</MenuItem>
                      <MenuItem value={'MX'}>México</MenuItem>
                      <MenuItem value={'PE'}>Perú</MenuItem>
                      <MenuItem value={'UY'}>Uruguay</MenuItem>
                      <MenuItem value={'Other'}>Otro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                >
                  <FormControl
                    {...register(`competitors.${i}.state`)}
                    variant='standard'
                    fullWidth
                  >
                    {showStates ? (
                      <>
                        <InputLabel id={`competitor${i}-state-label`}>Provincia</InputLabel>
                        <Select
                          defaultValue={'Chaco'}
                          onChange={(element) => setValue(`competitors.${i}.state`, element.target.value)}
                          labelId={`competitor${i}-state-label`}
                          label='Provincia'
                          error={!!(errors.competitors && errors.competitors[i]?.state?.message)}
                        >
                          {ArStates.map((field, j) => (
                            <MenuItem
                              key={j}
                              value={field}
                            >
                              {field}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <TextField
                        label='Provincia'
                        helperText={errors.competitors && errors.competitors[i]?.state?.message}
                        error={!!(errors.competitors && errors.competitors[i]?.state?.message)}
                        variant='standard'
                        fullWidth
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                >
                  <TextField
                    {...register(`competitors.${i}.city`)}
                    label='Ciudad'
                    helperText={errors.competitors && errors.competitors[i]?.city?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.city?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                >
                  <TextField
                    {...register(`competitors.${i}.tel`)}
                    label='Nro de Teléfono'
                    helperText={errors.competitors && errors.competitors[i]?.tel?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.tel?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                >
                  <TextField
                    {...register(`competitors.${i}.email`)}
                    label='E-Mail'
                    helperText={errors.competitors && errors.competitors[i]?.email?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.email?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={i ? 12 : 6}
                >
                  <TextField
                    {...register(`competitors.${i}.fishingLicense`)}
                    label='Licencia de Pesca nro'
                    helperText={errors.competitors && errors.competitors[i]?.fishingLicense?.message}
                    error={!!(errors.competitors && errors.competitors[i]?.fishingLicense?.message)}
                    variant='standard'
                    fullWidth
                  />
                </Grid>
                {!i && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <TextField
                      {...register(`competitors.${i}.helmsmanLicense`)}
                      label='Licencia de Timonel nro'
                      helperText={errors.competitors && errors.competitors[i]?.helmsmanLicense?.message}
                      error={!!(errors.competitors && errors.competitors[i]?.helmsmanLicense?.message)}
                      variant='standard'
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
              <ButtonRemoveCompetitor
                variant='text'
                onClick={() => remove(i)}
              >
                x
              </ButtonRemoveCompetitor>
            </InfoCard>
          ))}
          {apiError && (
            <Grid
              item
              xs={12}
            >
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {apiError}
              </Alert>
            </Grid>
          )}
          <Button
            sx={{ mt: '20px', mb: 0 }}
            variant='contained'
            onClick={() => append({} as Competitor)}
            disabled={getValues('competitors') && getValues('competitors').length > 3}
          >
            {getValues('competitors') && !getValues('competitors').length ? 'Registrar Timonel' : 'Añadir Tripulante'}
          </Button>
          <Button
            type='submit'
            className='register-button'
            variant='contained'
            disabled={getValues('competitors') && !getValues('competitors').length}
          >
            Inscribirme
          </Button>
        </FormBox>
        {isDesktop && <Carousel side='right' />}
      </MainBox>
    </>
  );
};

export default InscriptionForm;
