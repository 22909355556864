import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { User } from '../../../globals/types';
import Carousel from '../../../components/Carousel/Carousel';
import PescaChacoLogo from '../../../components/PescaChacoLogo/PescaChacoLogo';
import { useAuth } from '../../../context/AuthProvider';
import { FormBox, MainBox } from './SignUp.styles';
import { useScreen } from '../../../hooks/useScreen';

const schema = yup.object().shape({
  email: yup.string().required('Campo requerido').email('Tienes que introducir un email válido'),
  password: yup
    .string()
    .required('Campo requerido')
    .min(8, 'Tu contraseña debe tener al menos 8 caracteres')
    .max(25, 'Tu contraseña puede tener como máximo 25 caracteres'),
  confirmPwd: yup
    .string()
    .required('Campo requerido')
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const SignUp = () => {
  const { isMobile } = useScreen();
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const submitForm = (data: User) => {
    setApiError(null);
    signUp(data)
      .then(() => navigate('/inscription'))
      .catch((reason) => {
        if (reason.response.data.message === 'User already exists') {
          setApiError('Ya existe un usuario registrado con este correo electrónico');
        }
      });
  };

  return (
    <MainBox className={isMobile ? 'mobile' : ''}>
      <Carousel />
      <FormBox
        component='form'
        onSubmit={handleSubmit(submitForm)}
      >
        <PescaChacoLogo />
        <Typography variant='h1'>Bienvenido!</Typography>
        <Typography variant='h3'>Regístrate para acceder al formulario de inscripción</Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('firstName')}
              type='text'
              autoComplete='given-name'
              name='firstName'
              required
              fullWidth
              id='firstName'
              label='Nombre'
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('lastName')}
              required
              fullWidth
              id='lastName'
              label='Apellido'
              name='lastName'
              autoComplete='family-name'
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('email')}
              required
              fullWidth
              id='email'
              label='Correo electrónico'
              name='email'
              type='email'
              autoComplete='email'
              helperText={errors.email?.message}
              error={!!errors.email?.message}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('password')}
              required
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='new-password'
              helperText={errors.password?.message}
              error={!!errors.password?.message}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('confirmPwd')}
              required
              fullWidth
              name='confirmPwd'
              label='Confirmar Contraseña'
              type='password'
              id='confirmPwd'
              autoComplete='new-password'
              helperText={errors.confirmPwd?.message}
              error={!!errors.confirmPwd?.message}
            />
          </Grid>
          {apiError && (
            <Grid
              item
              xs={12}
            >
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {apiError}
              </Alert>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <Button
              type='submit'
              className='register-button'
              variant='contained'
            >
              Registrarme
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent='center'
        >
          <Grid item>
            <Link
              className='redirection-login'
              component={RouterLink}
              to='/sign-in'
            >
              <Typography variant='body2'>¿Ya tenes una cuenta?</Typography>
              <Typography
                variant='body2'
                fontWeight={600}
                className='sign-in-link'
              >
                Iniciar sesión
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </FormBox>
    </MainBox>
  );
};

export default SignUp;
