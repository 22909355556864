import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { User } from '../../../globals/types';
import Carousel from '../../../components/Carousel/Carousel';
import PescaChacoLogo from '../../../components/PescaChacoLogo/PescaChacoLogo';
import { useAuth } from '../../../context/AuthProvider';
import { FormBox, MainBox } from './SignIn.styles';
import { useScreen } from '../../../hooks/useScreen';

const schema = yup.object().shape({
  email: yup.string().required('Campo requerido').email('Tienes que introducir un email válido'),
  password: yup
    .string()
    .required('Campo requerido')
    .min(8, 'Tu contraseña debe tener al menos 8 caracteres')
    .max(25, 'Tu contraseña puede tener como máximo 25 caracteres'),
});

const SignIn = () => {
  const { isMobile } = useScreen();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: User) => {
    setApiError(null);
    signIn(data)
      .then(() => navigate('/inscription'))
      .catch((e) =>
        e.response && e.response.status === 401 ? setApiError('Credenciales Inválidas') : setApiError(e.message),
      );
  };

  return (
    <MainBox className={isMobile ? 'mobile' : ''}>
      <Carousel />
      <FormBox
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <PescaChacoLogo />
        <Typography variant='h1'>Iniciar Sesión</Typography>
        <Typography variant='h3'>Accede para ver o modificar tus formularios de inscripción</Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('email')}
              required
              fullWidth
              id='email'
              label='Correo electrónico'
              name='email'
              type='email'
              autoComplete='email'
              helperText={errors.email?.message}
              error={!!errors.email?.message}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              variant='standard'
              {...register('password')}
              required
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              autoComplete='new-password'
              helperText={errors.password?.message}
              error={!!errors.password?.message}
            />
          </Grid>
          {apiError && (
            <Grid
              item
              xs={12}
            >
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {apiError}
              </Alert>
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <Button
              type='submit'
              className='register-button'
              variant='contained'
            >
              Acceder
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='center'
        >
          <Grid item>
            <Link
              className='redirection-sign-up'
              component={RouterLink}
              to='/sign-up'
            >
              <Typography variant='body2'>¿Aun no tenes una cuenta?</Typography>
              <Typography
                variant='body2'
                fontWeight={600}
                className='sign-up-link'
              >
                Regístrate
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </FormBox>
    </MainBox>
  );
};

export default SignIn;
