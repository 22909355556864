import { useScreen } from '../../hooks/useScreen';
import { LogoBox, MainBox } from './PescaChacoLogoNew.styles';

const PescaChacoLogo = () => {
  const { isMobile } = useScreen();

  return (
    <MainBox>
      <LogoBox className={isMobile ? 'mobile' : 'desktop'} />
    </MainBox>
  );
};

export default PescaChacoLogo;
