import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  display: inline-flex;
  position: absolute;
  background-color: #00000050;
  border-radius: 100px;

  &.desktop {
    right: 20px;
    top: 20px;
  }

  &.mobile {
    bottom: 230px;
    left: calc(50% - 57.5px);
  }
`;

export const LabelBox = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
