import { MainBox } from './HalfTable.styles';

interface Props {
  state: 'free' | 'reserved' | 'selected';
  letter: string;
  disabled: boolean;
  onClick(): void;
}

const HalfTable = ({ state, letter, disabled, onClick }: Props) => {
  return (
    <MainBox
      disabled={disabled}
      onClick={onClick}
      className={`${state} ${letter}`}
    />
  );
};

export default HalfTable;
