import styled from '@emotion/styled/macro';
import { Box, Button } from '@mui/material';

export const MainBox = styled(Box)`
  position: relative;
  min-height: 100vh;
  width: calc(100vw - 400px);
  background: url(/images/fishing1.webp) no-repeat center;
  background-size: auto 100%;

  &.right {
    width: 35vw;
  }

  &.mobile {
    width: 100vw;
  }
`;

export const InformationBox = styled(Box)`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 400px;

  ${MainBox}.mobile & {
    width: calc(100vw - 80px);
    top: 150px;
  }

  .title {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .event-data {
    font-family: Poppins, sans-serif;
    color: #ffffff;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .date {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const ButtonStart = styled(Button)`
  width: 100%;
  position: absolute;
  bottom: 140px;
  background-color: transparent;
  text-transform: capitalize;
  color: white;
  display: flex;
  flex-direction: column;

  .south-icon {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const BottomBand = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: #00000050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
