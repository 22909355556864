import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-size: cover;
`;

export const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px 35px;
  height: 100vh;
  overflow-y: scroll;
  width: 65vw;

  &.mobile {
    width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TablesContainer = styled(Box)`
  max-width: calc(100vw - 40px);
  overflow-x: scroll;

`;

export const TablesBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  position: relative;
  margin-top: 20px;
`;

export const TablesSection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 136px;
  max-width: 136px;
  margin: 7px;
`;

export const EmptySpace = styled(Box)`
  width: 30px;
  height: 30px;
`;

export const ScenarioBox = styled(Box)`
  position: absolute;
  left: 240px;
  top: -20px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BathroomBox = styled(Box)`
  position: absolute;
  right: -50px;
  bottom: 10px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  width: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .bathroom-icon {
    color: #ffffff;
  }
`;
