import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LogoBox = styled(Box)`
  width: 180px;
  height: 90px !important;
  background: url(/images/logo-loteria-chaquenia.svg) no-repeat;
  background-size: contain;
`;
