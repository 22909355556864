import { Navigate, Route, Routes } from 'react-router-dom';
import CarouselPage from '../pages/CarouselPage/CarouselPage';
import InscriptionForm from '../pages/InscriptionForm/InscriptionForm';
import TableReservation from '../pages/TableReservation/TableReservation';

const UserRoutes = () => {
  return (
    <Routes>
      <Route
        path='inscription-form'
        element={<InscriptionForm />}
      />
      <Route
        path='inscriptions/:inscriptionId/table-reservation'
        element={<TableReservation />}
      />
      <Route
        path='carousel-page'
        element={<CarouselPage />}
      />
      <Route
        path='*'
        element={
          <Navigate
            replace
            to='/inscription-form'
          />
        }
      />
    </Routes>
  );
};

export default UserRoutes;
