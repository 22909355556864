import { ButtonBase, styled } from '@mui/material';

export const MainBox = styled(ButtonBase)`
  width: 15px;
  height: 30px;

  &.a {
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
  }
  &.b {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }

  &.free {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
  &.reserved {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  &.selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
