import axios from 'axios';

const localStorageUserKey = '@pescachaco:user';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) cleanExpiredSession();
    return Promise.reject(error);
  },
);

const cleanExpiredSession = () => {
  delete api.defaults.headers.common.Authorization;
  localStorage.removeItem(localStorageUserKey);
};

export default api;
