import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Logout, MenuSharp, PhishingOutlined, SailingOutlined, SetMealOutlined } from '@mui/icons-material';
import { Button, SwipeableDrawer } from '@mui/material';
import { useScreen } from '../../hooks/useScreen';
import { useAuth } from '../../context/AuthProvider';
import { HamburgerIcon, MenuBox } from './Hamburger.styles';

const Hamburger = () => {
  const { isMobile } = useScreen();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useAuth();

  return (
    <>
      {user ? (
        <>
          <HamburgerIcon onClick={() => setIsOpen(true)}>
            <MenuSharp />
          </HamburgerIcon>
          <SwipeableDrawer
            anchor='left'
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
          >
            <MenuBox>
              {isMobile && (
                <Button
                  variant='text'
                  component={Link}
                  to={'/carousel-page'}
                  className='menu-element'
                >
                  <PhishingOutlined
                    className='menu-element-icon'
                    color='secondary'
                  />
                  Evento
                </Button>
              )}
              <Button
                variant='text'
                component={Link}
                to={'/inscription-form'}
                className='menu-element'
              >
                <SetMealOutlined
                  className='menu-element-icon'
                  color='secondary'
                />
                Mis Inscripciones
              </Button>
              <Button
                variant='text'
                component={Link}
                to={'/inscription-form'}
                className='menu-element'
              >
                <SailingOutlined
                  className='menu-element-icon'
                  color='secondary'
                />
                Nueva Inscripción
              </Button>
              <Button
                variant='text'
                onClick={signOut}
                className='menu-element'
              >
                <Logout
                  className='menu-element-icon'
                  color='error'
                />
                Cerrar sesión
              </Button>
            </MenuBox>
          </SwipeableDrawer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Hamburger;
