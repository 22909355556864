import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from '../pages/auth/SignIn/SignIn';
import SignUp from '../pages/auth/SignUp/SignUp';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path='sign-in'
        element={<SignIn />}
      />
      <Route
        path='sign-up'
        element={<SignUp />}
      />
      <Route
        path='*'
        element={
          <Navigate
            replace
            to='/sign-up'
          />
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
