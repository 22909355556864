import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MainBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LogoBox = styled(Box)`
  background: url(/images/logo1.svg) no-repeat;
  background-size: contain;
  background-repeat: no-repeat;

  &.mobile {
    width: 158px;
    height: 104px !important;
    margin-top: 30px;
  }

  &.desktop {
    width: 198px;
    height: 155px !important;
  }
`;
