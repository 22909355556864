import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { useScreen } from '../../hooks/useScreen';
import { LabelBox, MainBox } from './SeatsCounter.styles';

const SeatsCounter = (props: CircularProgressProps & { percentageOccupied: number; seatsLeft: number }) => {
  const { isMobile } = useScreen();

  return (
    <MainBox className={isMobile ? 'mobile' : 'desktop'}>
      <CircularProgress
        color='secondary'
        variant='determinate'
        value={props.percentageOccupied}
        vocab={'lugares'}
        size={isMobile ? '115px' : '130px'}
        thickness={1.3}
      />
      <LabelBox>
        <Typography color='background.paper'>Quedan</Typography>
        <Typography
          variant='caption'
          component='div'
          color='secondary'
          fontSize='50px'
          fontWeight={300}
          lineHeight='50px'
        >
          {props.seatsLeft}
        </Typography>
        <Typography color='background.paper'>Lugares</Typography>
      </LabelBox>
    </MainBox>
  );
};

export default SeatsCounter;
