import { FishingEvent } from '../globals/types';
import api from '../services/api';

const useFishingEvent = () => {
  const fetchFishingEvent = async (fishingEventId: string): Promise<FishingEvent> => {
    const { data } = await api.get(`/fishing-events/${fishingEventId}`);
    return data;
  };

  const fetchAmountOfConfirmedInscriptions = async (fishingEventId: string): Promise<number> => {
    const { data } = await api.get(`inscriptions/fishing-event/${fishingEventId}/count`);
    return data;
  };

  return { fetchFishingEvent, fetchAmountOfConfirmedInscriptions };
};

export { useFishingEvent };
