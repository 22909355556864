import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { WcOutlined } from '@mui/icons-material';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { FishingEvent, Inscription, Table as TableProps } from '../../globals/types';
import Carousel from '../../components/Carousel/Carousel';
import Hamburger from '../../components/Hamburger/Hamburger';
import PescaChacoLogo from '../../components/PescaChacoLogo/PescaChacoLogo';
import Table from './components/Table/Table';
import { useFishingEvent } from '../../hooks/useFishingEvent';
import { useInscription } from '../../hooks/useInscription';
import { useScreen } from '../../hooks/useScreen';
import {
  BathroomBox,
  ContentBox,
  MainBox,
  ScenarioBox,
  TablesBox,
  TablesContainer,
  TablesSection,
} from './TableReservation.styles';

interface TableReservationProps {
  inscription: Inscription;
  fishingEvent: FishingEvent;
}

const TableReservation = () => {
  const { inscriptionId } = useParams();
  const { isDesktop, isMobile } = useScreen();
  const { fetchInscription, patchInscription } = useInscription();
  const { fetchFishingEvent } = useFishingEvent();
  const [tableMatrix, setTableMatrix] = useState<TableProps[][]>();
  const [selectedTable, setSelectedTable] = useState<string>();
  const { data, isLoading, error } = useQuery<TableReservationProps>(
    ['fetchInscription', inscriptionId],
    () => fetchData(),
    { refetchOnWindowFocus: false },
  );
  const fetchData = async (): Promise<TableReservationProps> => {
    const organizeTableMatrix = (tables: TableProps[]) => {
      const tableMatrixOrganization = [
        [0, 1, 2, 3],
        [4, 5],
        [6, 7],
        [8, 9, 10, 11],
        [12, 13, 14, 15, 28, 29, 30, 31, 44, 45, 46, 47],
        [16, 17, 18, 19, 32, 33, 34, 35, 48, 49, 50, 51],
        [20, 21, 22, 23, 36, 37, 38, 39, 52, 53, 54, 55],
        [24, 25, 26, 27, 40, 41, 42, 43, 56, 57, 58, 59],
        [60, 61, 62, 63, 76, 77, 78, 79],
        [64, 65, 66, 67, 80, 81, 82, 83],
        [68, 69, 70, 71, 84, 85, 86, 87],
        [72, 73, 74, 75, 88, 89, 90, 91],
        [92, 93, 94, 95, 108, 109, 110, 111],
        [96, 97, 98, 99, 112, 113, 114, 115],
        [100, 101, 102, 103, 116, 117, 118, 119],
        [104, 105, 106, 107, 120, 121, 122, 123],
      ];
      const organizedTableMatrix = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []] as TableProps[][];
      tables.forEach((table, i) => {
        organizedTableMatrix[tableMatrixOrganization.findIndex((section) => section.includes(i))].push(table);
      });
      // Making some room for the scenario
      organizedTableMatrix[1].push(...([{}, {}] as TableProps[]));
      organizedTableMatrix[2].unshift(...([{}, {}] as TableProps[]));

      setTableMatrix(organizedTableMatrix);
    };

    const inscription = await fetchInscription(inscriptionId || '');
    const fishingEvent = await fetchFishingEvent(inscription.fishingEvent);
    organizeTableMatrix(fishingEvent.tables);

    return {
      inscription,
      fishingEvent,
    };
  };
  if (isLoading) return <LinearProgress />;
  if (!inscriptionId || !data || error) return <Typography>Inscripción o Evento no encontrados</Typography>;

  const selectTable = (table: TableProps, letter: string) => {
    // Marking the table as selected
    table[letter === 'a' ? 'statusA' : 'statusB'] = 'selected';

    // Marking the previous table as free
    if (selectedTable) {
      const prevTableIndex = data.fishingEvent.tables.findIndex((table) => table.number === parseInt(selectedTable));
      if (prevTableIndex === -1) throw new Error('Mesa inválida');
      data.fishingEvent.tables[prevTableIndex][
        selectedTable.charAt(selectedTable.length - 1) === 'a' ? 'statusA' : 'statusB'
      ] = 'free';
    }

    setSelectedTable(`${table.number}${letter}`);
  };

  const confirmTable = async () => {
    await patchInscription(inscriptionId, { table: selectedTable });
    // TODO: Mover esta confirmación después de que se realiza el pago
    await patchInscription(inscriptionId, { confirmed: true });
  };

  return (
    <>
      <Hamburger />
      <MainBox>
        <ContentBox className={isMobile ? 'mobile' : ''}>
          <PescaChacoLogo />
          <Typography variant='h1'>Reservar Mesa</Typography>
          <Typography variant='h3'>
            Seleccione la mesa que desea reservar. Recuerde que reservará media mesa, que serían 4 (cuatro) lugares
          </Typography>
          <Box>
            <TablesContainer>
              <TablesBox>
                <ScenarioBox>
                  <Typography
                    variant='body2'
                    color={'white'}
                  >
                    ESCENARIO
                  </Typography>
                </ScenarioBox>
                <BathroomBox>
                  <WcOutlined className='bathroom-icon' />
                </BathroomBox>
                {tableMatrix &&
                  tableMatrix.map((tableSection, i) => (
                    <TablesSection key={i}>
                      {tableMatrix &&
                        tableMatrix[i].map((table, j) => (
                          <Table
                            key={j}
                            number={table.number}
                            statusA={table.statusA}
                            statusB={table.statusB}
                            onClickA={() => selectTable(table, 'a')}
                            onClickB={() => selectTable(table, 'b')}
                            disabledA={
                              data.fishingEvent.tables.find((el) => el.number === table.number)?.statusA === 'reserved'
                            }
                            disabledB={
                              data.fishingEvent.tables.find((el) => el.number === table.number)?.statusB === 'reserved'
                            }
                          />
                        ))}
                    </TablesSection>
                  ))}
              </TablesBox>
            </TablesContainer>
          </Box>
          <Button
            variant='contained'
            onClick={confirmTable}
            disabled={!selectedTable}
          >
            Reservar mesa {selectedTable}
          </Button>
        </ContentBox>
        {isDesktop && <Carousel side='right' />}
      </MainBox>
    </>
  );
};

export default TableReservation;
