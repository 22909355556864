import Carousel from '../../components/Carousel/Carousel';
import Hamburger from '../../components/Hamburger/Hamburger';

const CarouselPage = () => {
  return (
    <>
      <Hamburger />
      <Carousel />
    </>
  );
};

export default CarouselPage;
