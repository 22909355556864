import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { South } from '@mui/icons-material';
import { LinearProgress, Typography } from '@mui/material';
import { FishingEvent } from '../../globals/types';
import { useFishingEvent } from '../../hooks/useFishingEvent';
import { useScreen } from '../../hooks/useScreen';
import LoteriaChaqueniaLogo from '../LoteriaChaqueniaLogo/LoteriaChaqueniaLogo';
import PescaChacoLogoNew from '../PescaChacoLogoNew/PescaChacoLogoNew';
import SeatsCounter from '../SeatsCounter/SeatsCounter';
import { BottomBand, ButtonStart, InformationBox, MainBox } from './Carousel.styles';

interface Props {
  side?: 'left' | 'right';
}

interface FishingEventProps {
  fishingEvent: FishingEvent;
  amountOfConfirmedInscriptions: number;
}

// TODO: Agregar hora de largada
const Carousel = ({ side = 'left' }: Props) => {
  const { isMobile } = useScreen();
  const navigate = useNavigate();
  const { fetchFishingEvent, fetchAmountOfConfirmedInscriptions } = useFishingEvent();
  const { data, isLoading, error } = useQuery<FishingEventProps>(
    ['fishingEvent', process.env.REACT_APP_FISHING_EVENT_ID],
    () => fetchData(),
  );
  const fetchData = async (): Promise<FishingEventProps> => {
    const fishingEventId = process.env.REACT_APP_FISHING_EVENT_ID as string;
    const fishingEvent = await fetchFishingEvent(fishingEventId);
    const amountOfConfirmedInscriptions = await fetchAmountOfConfirmedInscriptions(fishingEventId);
    return {
      fishingEvent,
      amountOfConfirmedInscriptions,
    };
  };

  if (isLoading) return <LinearProgress />;
  if (!data || error) return <Typography>Evento no encontrado</Typography>;

  const access = () => {
    const pescaChacoLogoElement = document.getElementById('pesca-chaco-logo');
    if (pescaChacoLogoElement) {
      pescaChacoLogoElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/inscription-form');
    }
  };

  return (
    <MainBox className={`${isMobile && 'mobile '}${side}`}>
      {isMobile && <PescaChacoLogoNew />}
      <InformationBox>
        <Typography className='title'>&quot;{data.fishingEvent.title}&quot;</Typography>
        <Typography className='event-data'>{data.fishingEvent.subTitle}</Typography>
        <Typography className='date'>Fecha: {new Date(data.fishingEvent.date).toLocaleDateString('ES')}</Typography>
        <Typography className='event-data'>{data.fishingEvent.showDetails}</Typography>
      </InformationBox>
      <SeatsCounter
        percentageOccupied={Math.ceil((data.amountOfConfirmedInscriptions / data.fishingEvent.maxBoats) * 100)}
        seatsLeft={data.fishingEvent.maxBoats - data.amountOfConfirmedInscriptions}
      />
      {isMobile && (
        <ButtonStart
          variant='text'
          onClick={access}
        >
          <Typography
            variant='h6'
            color={'white'}
          >
            Acceder
          </Typography>
          <South className='south-icon' />
        </ButtonStart>
      )}
      <BottomBand>
        <LoteriaChaqueniaLogo />
        <Typography
          variant='h4'
          color='#ffffff'
          fontStyle='italic'
          marginTop='10px'
        >
          Organiza: &quot;{data.fishingEvent.eventPlanner}&quot;
          <br />
          Fiscaliza: &quot;{data.fishingEvent.auditor}&quot;
        </Typography>
      </BottomBand>
    </MainBox>
  );
};

export default React.memo(Carousel);
