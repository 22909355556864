import { useAuth } from './context/AuthProvider';
import PrivateRoutes from './routes/private.routes';
import PublicRoutes from './routes/public.routes';

const App = () => {
  const { user } = useAuth();

  return user && user.role ? <PrivateRoutes /> : <PublicRoutes />;
};

export default App;
