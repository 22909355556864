import { Table as TableInterface } from '../../../../globals/types';
import HalfTable from '../HalfTable/HalfTable';
import { EmptySpace, MainBox } from './Table.styles';

interface TableProps extends TableInterface {
  onClickA(): void;
  onClickB(): void;
  disabledA: boolean;
  disabledB: boolean;
}

const Table = ({ statusA, statusB, onClickA, onClickB, disabledA, disabledB }: TableProps) => {
  return (
    <MainBox>
      {statusA !== undefined && statusB !== undefined ? (
        <>
          <HalfTable
            state={statusA}
            letter='a'
            onClick={onClickA}
            disabled={disabledA}
          />
          <HalfTable
            state={statusB}
            letter='b'
            onClick={onClickB}
            disabled={disabledB}
          />
        </>
      ) : (
        <EmptySpace />
      )}
    </MainBox>
  );
};

export default Table;
