import { useEffect, useState } from 'react';
import { screenSizes } from '../globals/screen-sizes';

const useScreen = () => {
  const checkIfMobile = (): boolean => window.innerWidth <= parseInt(screenSizes.mobileOrTablet);
  const checkIfDesktop = (): boolean => window.innerWidth > parseInt(screenSizes.mobileOrTablet);

  const [isMobile, setMobile] = useState(checkIfMobile());
  const [isDesktop, setDesktop] = useState(checkIfDesktop());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobile(checkIfMobile());
      setDesktop(checkIfDesktop());
    });
  });

  return { isMobile, isDesktop };
};

export { useScreen };
