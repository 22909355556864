import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';

export const MainBox = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-size: cover;
`;

export const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 35px;
  height: 100vh;
  overflow-y: scroll;
  max-width: 100vw;
  width: 50vw;

  &.mobile {
    width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InfoCard = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 10px;
  padding: 20px 20px 40px 20px;
  margin-bottom: 20px;
  position: relative;
`;

export const ButtonRemoveCompetitor = styled(Button)`
  position: absolute;
  margin: 0;
  top: 10px;
  right: 10px;
  width: 15px;
`;
