import { createTheme } from '@mui/material/styles';
import { Theme as MuiTheme } from '@mui/material/styles/createTheme';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends MuiTheme {}
}

const theme = createTheme({
  palette: {
    primary: {
      dark: '#707070',
      main: '#000000',
      light: '#8d8d8d',
    },
    secondary: {
      main: '#fd7800',
      light: '#e6b501',
    },
    background: {
      default: '#c4c4c4',
      paper: '#e6e6e6',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
      fontSize: 27,
      lineHeight: '40px',
      textAlign: 'left',
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
      fontSize: 22,
      color: '#444444',
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 12,
      color: '#8d8d8d',
      textAlign: 'left',
      marginBottom: '20px',
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      fontSize: 14,
      color: '#000000',
    },
    button: {
      textTransform: 'uppercase',
      fontSize: 19,
      width: 100,
      borderRadius: 10,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          marginTop: 20,
          marginBottom: 20,
          width: '100%',
          maxWidth: '350px',
        },
      },
    },
  },
});

export default theme;
