import { Typography } from '@mui/material';
import { useAuth } from '../context/AuthProvider';
import UserRoutes from './user.routes';

const PrivateRoutes = () => {
  const { user } = useAuth();

  return user && user.role === 'user' ? (
    <UserRoutes />
  ) : (
    <Typography>Todavía no soportamos el rol {user?.role}</Typography>
  );
};

export default PrivateRoutes;
