import { LogoBox, MainBox } from './LoteriaChaqueniaLogo.styles';

const LoteriaChaqueniaLogo = () => {
  return (
    <MainBox>
      <LogoBox></LogoBox>
    </MainBox>
  );
};

export default LoteriaChaqueniaLogo;
