import { Box, styled } from '@mui/material';

export const MainBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 2px;
`;

export const EmptySpace = styled(Box)`
  width: 30px;
  height: 30px;
`;
